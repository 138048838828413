import { parse } from "papaparse";

export function getCsvData(filename: string): Promise<any[]> {
  return new Promise((resolve) => {
    parse(`/assets/${filename}`, {
      download: true,
      header: true,
      skipEmptyLines: true,
      complete: (results) => resolve(results.data),
    });
  });
}
