import { useState } from "react";
import { BsYoutube } from "react-icons/bs";
import styled from "styled-components";
import { ResumeEvent } from "../../models/resume-event";
import Video from "./video";

interface Props {
  event: ResumeEvent;
}
export default function Event({ event }: Props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <li className="event" data-date={event.Date.toLocaleDateString()}>
      <h3>{event.Title}</h3>
      <div className="clearfix">
        <pre className="float-start m-0 text-wrap">{event.Description}</pre>
        {event.VideoUrl && <Icon onClick={() => setShowModal(true)} />}
        {showModal && (
          <Video event={event} onHide={() => setShowModal(false)} />
        )}
      </div>
    </li>
  );
}

const Icon = styled(BsYoutube)`
  cursor: pointer;
  float: right;
  font-size: 1.5rem;
  color: #ff0000;
`;
