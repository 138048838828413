import { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import styled from "styled-components";
import { getMarkdownText } from "../services/markdown";

interface FooterData {
  phone: string;
  email: string;
}

export default function Footer() {
  const [footer, setFooter] = useState<FooterData | null>();

  useEffect((): void => {
    const getData = async (): Promise<void> => {
      const response = await getMarkdownText("footer.txt");
      const phone = response.match(/\[PhoneIcon\] (.*)/)?.[1] ?? "";
      const email = response.match(/\[EmailIcon\] (.*)/)?.[1] ?? "";
      setFooter({ phone, email });
    };
    getData();
  }, [setFooter]);

  return (
    <Navbar collapseOnSelect expand="sm" bg="light" variant="light">
      <Center>
        <Item className="text-dark" href={"tel:" + footer?.phone}>
          <BsTelephoneFill /> {footer?.phone}
        </Item>
        <Item className="text-dark" href={"mailto:" + footer?.email}>
          <MdEmail /> {footer?.email}
        </Item>
      </Center>
    </Navbar>
  );
}

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const Item = styled.a`
  margin: 0.5rem 1rem;
  text-decoration: none;
`;
