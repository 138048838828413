import Equipment from "../../models/equipment";
import Item from "./item";

interface Props {
  category: string;
  equipment: Equipment[];
}
export default function Category({ category, equipment }: Props) {
  const items = equipment.map((item, index) => (
    <div key={item.Name + index} className="col">
      <Item item={item} />
    </div>
  ));

  return (
    <section className="row flex-lg-row-reverse p-3 mx-0 mb-3 rounded-3 border shadow-lg bg-light">
      <h2 className="mt-3 mb-3">{category}</h2>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 m-0 g-3">
        {items}
      </div>
      <div className="pb-3"></div>
    </section>
  );
}
