import { ReactNode } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export default function Header() {
  return (
    <Navbar collapseOnSelect expand="md" bg="light" variant="light">
      <Container>
        <Brand>
          <BrandImage
            src="https://www.thejhonnys.com.au/assets/Equipmentphotos/cropped-cropped-logo-small-e1617851986540.gif"
            alt="The Jhonnys logo"
          />
          &nbsp; The Jhonnys
        </Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <NavigableLink to="/">Home</NavigableLink>
            <NavigableLink to="/resume">Resume</NavigableLink>
            <NavigableLink to="/equipment-list">Equipment</NavigableLink>
            <NavigableLink to="/videos">Videos</NavigableLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

const Brand = styled(Navbar.Brand)`
  font-size: 2.5em;
  font-family: "Carrois Gothic", sans-serif;

  @media (max-width: 380px) {
    font-size: 1.5em;
  }
`;

const BrandImage = styled.img`
  width: 37px;
  height: 70px;
`;

function NavigableLink({ to, children }: { to: string; children: ReactNode }) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
    >
      {children}
    </NavLink>
  );
}
