import { useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import styled from "styled-components";
import Loading from "../../components/loading";
import { getMarkdownText } from "../../services/markdown";

export default function Home() {
  let [summary, setSummary] = useState("");

  useEffect(() => {
    const getSummary = async () => {
      setSummary(await getMarkdownText("home.md"));
    };
    getSummary();
  }, []);

  return (
    <div className="row flex-lg-row-reverse p-3 rounded-3 border shadow-lg bg-light">
      <div className="col-12 col-lg-6 d-flex justify-content-center">
        <Image
          alt="example of film lighting on set"
          src="https://www.thejhonnys.com.au/assets/Equipmentphotos/2.webp"
        />
      </div>
      {summary ? (
        <Summary className="col-12 col-lg-6">
          <ReactMarkdown children={summary}></ReactMarkdown>
        </Summary>
      ) : (
        <Loading />
      )}
    </div>
  );
}

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

const Summary = styled.div`
  h1,
  h2 {
    font-family: "Carrois Gothic", sans-serif;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 1rem;
    font-size: 3rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  p {
    font-size: 1.25rem;
    font-weight: 300;
  }
`;
