import { useState, ReactElement } from "react";
import Equipment from "../../models/equipment";
import styled from "styled-components";
import { Card } from "react-bootstrap";

interface Props {
  item: Equipment;
  children?: ReactElement;
}
export default function Item({ item, children }: Props) {
  const [placeholderVisible, setPlaceholderVisible] = useState(false);

  return (
    <Card className="shadow-sm">
      <ImageWrapper className="bd-placeholder-img card-img-top">
        {placeholderVisible ? (
          <PlaceholderImage name={item.Name} />
        ) : (
          <Image
            name={item.Name}
            imageUrls={item.ImageUrls}
            onError={() => setPlaceholderVisible(true)}
          />
        )}
      </ImageWrapper>

      <Card.Header>
        {item.Quantity > 1 && <span>{item.Quantity}x </span>}
        {item.Name}

        <span className="float-end">{item.Price}</span>
      </Card.Header>

      <Card.Body>
        <pre>{item.Description}</pre>

        {children}
      </Card.Body>
    </Card>
  );
}

function PlaceholderImage({ name }: { name: string }): ReactElement {
  return (
    <svg
      className="bd-placeholder-img card-img-top"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Placeholder: Thumbnail"
      preserveAspectRatio="xMidYMid slice"
      focusable="false"
    >
      <title>{name} placeholder</title>
      <rect width="100%" height="100%" fill="#55595c"></rect>
      <text
        x="50%"
        y="50%"
        fill="#eceeef"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {name}
      </text>
    </svg>
  );
}

function Image({
  imageUrls,
  name,
  onError,
}: {
  imageUrls: string;
  name: string;
  onError: () => void;
}): ReactElement {
  return <img src={imageUrls} alt={"Image of " + name} onError={onError} />;
}

const ImageWrapper = styled.div`
  align-items: center;
  background-color: #55595c;
  display: flex;
  height: 225px;
  justify-content: center;
  overflow: hidden;

  // This is to fix the width not filling the same size as the border
  width: calc(100% + 2px);
  margin-left: -1px;
  // This fixes a white line in the van image
  margin-top: -1px;

  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }

  svg {
    height: 100%;
    width: 100%;
  }
`;
