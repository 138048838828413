import Modal from "react-bootstrap/esm/Modal";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { ResumeEvent } from "../../models/resume-event";

interface Props {
  event: ResumeEvent;
  onHide: () => void;
}
export default function Video({ event, onHide }: Props) {
  return (
    <LargeModal show={true} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{event.Title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ResizingVideoPlayer controls={true} url={event.VideoUrl} />
      </Modal.Body>
    </LargeModal>
  );
}

const LargeModal = styled(Modal)`
  .modal-dialog {
    width: min-content;
    max-width: 100%;
  }
`;

const ResizingVideoPlayer = styled(ReactPlayer)`
  width: min(calc(100vw - 3rem), 640px) !important;
  height: unset !important;
  aspect-ratio: 16 / 9;

  iframe {
    height: 100%;
    width: 100%;
  }
`;
