import { useEffect, useState } from "react";
import { ResumeEvent } from "../../models/resume-event";
import { getCsvData } from "../../services/csv";
import Event from "./event";
import styled from "styled-components";
import { getMarkdownText } from "../../services/markdown";
import Loading from "../../components/loading";
import ReactMarkdown from "react-markdown";

const convertToResumeEvent = (data: any): ResumeEvent => ({
  ...data,
  Date: new Date(data.Date),
});

interface ResumeCategories {
  [category: string]: ResumeEvent[];
}

export default function Resume() {
  let [details, setDetails] = useState("");
  const [resumeEvents, setEvents] = useState<ResumeCategories>({});

  useEffect(() => {
    (async () => {
      const [events, details] = await Promise.all([
        getCsvData("resume.csv"),
        getMarkdownText("resume.md"),
      ]);

      setDetails(details);
      setEvents(
        events.map(convertToResumeEvent).reduce((categories, event) => {
          categories[event.Type] ??= [];
          categories[event.Type].push(event);
          return categories;
        }, {} as ResumeCategories)
      );
    })();
  }, []);

  if (!resumeEvents) <Loading />;

  const timeline = Object.entries(resumeEvents).map(([category, events]) => (
    <Timeline>
      <h1>{category}</h1>
      <hr />
      {events.map((event) => (
        <Event key={event.Title} event={event} />
      ))}
    </Timeline>
  ));

  return (
    <section className="container p-0 rounded-3 border shadow-lg bg-light">
      <ResumeLinkContainer>
        <ResumeLink className="btn btn-primary" href="/assets/resume.pdf">
          Resume
        </ResumeLink>
      </ResumeLinkContainer>

      <Timeline>
        <Details className="text-center clearfix">
          <ReactMarkdown children={details} />
        </Details>
      </Timeline>

      {timeline}
    </section>
  );
}

const ResumeLinkContainer = styled.div`
  position: relative;

  @media (max-width: 450px) {
    & {
      position: unset;
      display: flex;
      align-content: center;
      margin: 0.5rem 0;
    }
  }
`;

const ResumeLink = styled.a`
  position: absolute;
  top: 1rem;
  right: 1rem;

  @media (max-width: 450px) {
    & {
      position: unset;
      margin: 0 auto;
    }
  }
`;

const Details = styled.li`
  padding-bottom: 3rem;

  h1 {
    // Override the timeline settings
    font-weight: 600 !important;
    font-size: 1rem !important;
    margin-bottom: 0px !important;
  }

  p {
    margin: 0;

    &:last-child {
      margin-bottom: 1rem;
    }
  }

  img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
  }
`;

const Timeline = styled.ul`
  border-left: 3px solid #727cf5;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: rgba(114, 124, 245, 0.09);
  margin: 0 auto;
  letter-spacing: 0.2px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 50px 50px 0px 50px;
  list-style: none;
  text-align: left;
  max-width: 50%;
  min-height: 100%;

  &:last-of-type {
    padding-bottom: 50px;
  }

  h1 {
    font-weight: 300;
    font-size: 1.4em;
  }

  h2,
  h3 {
    font-weight: 600;
    font-size: 1rem;
    padding-bottom: 10px;
    margin-bottom: 0px;
  }

  .event {
    border-bottom: 1px dashed #e8ebf1;
    margin-bottom: 25px;
    position: relative;

    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }

    &:before,
    &:after {
      position: absolute;
      display: block;
      top: 0;
    }

    &:before {
      left: -207px;
      content: attr(data-date);
      text-align: right;
      font-weight: 100;
      font-size: 0.9em;
      min-width: 120px;
    }

    &:after {
      -webkit-box-shadow: 0 0 0 3px #727cf5;
      box-shadow: 0 0 0 3px #727cf5;
      left: -55.8px;
      background: #fff;
      border-radius: 50%;
      height: 9px;
      width: 9px;
      content: "";
      top: 5px;
    }
  }

  @media (max-width: 767px) {
    max-width: calc(100% - 15px);
    margin: 0 0 0 auto;
    padding: 25px;

    .event {
      padding-top: 30px;

      &:before {
        left: 0px;
        text-align: left;
      }

      &:after {
        left: -31.8px;
      }
    }
  }
`;
