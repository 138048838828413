import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Video } from "../../models/videos";
import { getCsvData } from "../../services/csv";

export default function Videos() {
  const [videos, setVideos] = useState<Video[]>([]);

  useEffect(() => {
    const getVideos = async () => {
      const videos = await getCsvData("videos.csv");
      setVideos(videos);
    };
    getVideos();
  }, []);

  return (
    <div className="container row px-1 py-3 row bg-light border rounded-3 justify-content-center">
      {videos.map((video) => (
        <ReactPlayer
          controls={true}
          key={video.VideoUrl}
          url={video.VideoUrl}
        />
      ))}
    </div>
  );
}
