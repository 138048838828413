import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./components/header";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Footer from "./components/footer";

export default function App() {
  return (
    <Container>
      <Header />

      <Wrapper className="container">
        <Outlet />
      </Wrapper>

      <Footer />
    </Container>
  );
}

const Container = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  flex: 1;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* const icon = encodeURI(
  `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M384 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H384zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"/></svg>`
);

const SideBar = styled.div<{ left?: boolean; right?: boolean }>`
  width: 3rem;
  height: 100%;
  float: ${(props) => (props.left ? `left` : `right`)};
  left: ${(props) => (props.left ? `0;` : `unset`)};
  right: ${(props) => (props.right ? `0` : `unset`)};
  position: fixed;
  background-image: url("data:image/svg+xml,${encodeURI(icon)}");
  background-repeat: repeat-y;
`;
 */
