import { useEffect, useState } from "react";
import Loading from "../../components/loading";
import Equipment from "../../models/equipment";
import { getCsvData } from "../../services/csv";
import Category from "./category";
import Item from "./item";

const convertToEquipment = (data: any): Equipment => ({
  ...data,
  Quantity: parseInt(data.Quantity, 10),
});

export default function EquipmentList() {
  const [titleEquipment, setTitleEquipment] = useState<Equipment | null>(null);
  const [equipmentByCategory, setEquipmentByCategory] = useState<
    Record<string, Equipment[]>
  >({});

  useEffect((): void => {
    const getData = async (): Promise<void> => {
      const data = (await getCsvData("equipment.csv")).map(convertToEquipment);

      setTitleEquipment(data[0]);
      setEquipmentByCategory(
        data
          .slice(1)
          .reduce<Record<string, Equipment[]>>((categories, item) => {
            const category = item.Category;
            if (!categories[category]) categories[category] = [];
            categories[category].push(item);
            return categories;
          }, {})
      );
    };
    getData();
  }, []);

  if (!titleEquipment) return <Loading />;

  const categories = Object.keys(equipmentByCategory).map((category) => (
    <Category
      key={category}
      category={category}
      equipment={equipmentByCategory[category]}
    />
  ));

  return (
    <div className="container">
      <div className="clearfix mb-3">
        <a
          className="btn btn-primary float-start"
          href="/assets/van-equipment.pdf"
        >
          Van Equipment
        </a>

        <a
          className="btn btn-primary float-end"
          href="/assets/all-equipment.pdf"
        >
          All Equipment
        </a>
      </div>

      <Item item={titleEquipment} />

      <div className="mb-3"></div>

      {categories}
    </div>
  );
}
